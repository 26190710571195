import { mapEnumToOptions } from '@abp/ng.core';

export enum ProjectStatus {
  Active = 0,
  Closed = 1,
  Completed = 2,
  ForceClosed = 3,
}

export const projectStatusOptions = mapEnumToOptions(ProjectStatus);
