import type { CommentDto, CreateProjectExaminerDto, CreateProjectTeacherDto, ExaminerDto, ProjectBoardDto, ProjectListDto, ProjectProductDto, ProjectProductListDto, ProjectProductSubmissionDto, ProjectTaskBoardDto, ProjectTaskDetailsDto, ProjectTeacherDto, ProjectTeacherGuideDto, SubmitProjectStageDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CommentCreateDto, CommentParticipantCandidatesDto, ProjectListRequestDto } from '../common-contracts/projects/models';
import type { ProductDto } from '../products/models';

@Injectable({
  providedIn: 'root',
})
export class ProjectBoardService {
  apiName = 'Default';
  

  addProjectExaminer = (projectId: string, input: CreateProjectExaminerDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/Examiners`,
      body: input,
    },
    { apiName: this.apiName,...config });
  

  addProjectTeacher = (projectId: string, input: CreateProjectTeacherDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/Teachers`,
      body: input,
    },
    { apiName: this.apiName,...config });
  

  checkCanRemoveProjectTeacher = (projectId: string, projectTeacherId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/Teachers/${projectTeacherId}/CheckDelete`,
    },
    { apiName: this.apiName,...config });
  

  closeProject = (projectId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: `/api/app/project-board/${projectId}/Close`,
    },
    { apiName: this.apiName,...config });
  

  createComment = (projectId: string, taskId: string, input: CommentCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CommentDto>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/comments`,
      body: input,
    },
    { apiName: this.apiName,...config });
  

  createTaskResource = (projectId: string, taskId: string, resourceId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/resources`,
      params: { resourceId },
    },
    { apiName: this.apiName,...config });
  

  deleteComment = (projectId: string, taskId: string, commentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/comments/${commentId}`,
    },
    { apiName: this.apiName,...config });
  

  getGuides = (projectId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectTeacherGuideDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/guides`,
    },
    { apiName: this.apiName,...config });
  

  getList = (input: ProjectListRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ProjectListDto>>({
      method: 'GET',
      url: '/api/app/projects',
      params: { projectStatus: input.projectStatus, schoolClassId: input.schoolClassId, skipCount: input.skipCount, maxResultCount: input.maxResultCount, filter: input.filter, curriculumId: input.curriculumId, curriculumEducationStageId: input.curriculumEducationStageId, projectCategoryId: input.projectCategoryId, focusAreaId: input.focusAreaId, needsKit: input.needsKit },
    },
    { apiName: this.apiName,...config });
  

  getProduct = (projectId: string, productId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/products/${productId}`,
    },
    { apiName: this.apiName,...config });
  

  getProject = (projectId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectBoardDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}`,
    },
    { apiName: this.apiName,...config });
  

  getProjectExaminers = (projectId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ExaminerDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/Examiners`,
    },
    { apiName: this.apiName,...config });
  

  getProjectProductSubmissions = (projectId: string, productId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectProductSubmissionDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/products/${productId}/submissions`,
    },
    { apiName: this.apiName,...config });
  

  getProjectProductsThatNeedExaminers = (projectId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectProductListDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/products`,
    },
    { apiName: this.apiName,...config });
  

  getProjectTask = (projectId: string, taskId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectTaskDetailsDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}`,
    },
    { apiName: this.apiName,...config });
  

  getProjectTasks = (projectId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectTaskBoardDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/tasks`,
    },
    { apiName: this.apiName,...config });
  

  getProjectTeachers = (projectId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectTeacherDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/Teachers`,
    },
    { apiName: this.apiName,...config });
  

  getTaskCommentParticipantCandidates = (projectId: string, taskId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CommentParticipantCandidatesDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/comments/ParticipantCandidates`,
    },
    { apiName: this.apiName,...config });
  

  removeProjectExaminer = (projectId: string, projectTeacherId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/project-board/${projectId}/Examiners/${projectTeacherId}`,
    },
    { apiName: this.apiName,...config });
  

  removeProjectTeacher = (projectId: string, projectTeacherId: string, substituteTeacherIds: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/project-board/${projectId}/Teachers/${projectTeacherId}`,
      params: { substituteTeacherIds },
    },
    { apiName: this.apiName,...config });
  

  submitProjectStageByProjectIdAndStageId = (projectId: string, stageId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectProductDto[]>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/stages/${stageId}`,
    },
    { apiName: this.apiName,...config });
  

  submitProjectStageByProjectIdAndStageIdAndInputAndFiles = (projectId: string, stageId: string, input: SubmitProjectStageDto, files: FormData, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectBoardDto>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/stages/${stageId}`,
      body: files,
    },
    { apiName: this.apiName,...config });
  

  submitProjectTaskByProjectIdAndTaskIdAndFile = (projectId: string, taskId: string, file: FormData, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}`,
      body: file,
    },
    { apiName: this.apiName,...config });
  

  updateActivityStatus = (projectId: string, taskId: string, activityId: string, isCompleted: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectTaskDetailsDto>({
      method: 'GET',
      url: `/api/app/project-board/${projectId}/tasks/${taskId}/activities/${activityId}`,
      params: { isCompleted },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
