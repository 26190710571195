import {ExtendedEnvironment} from "./environment.interface";

const apiUrl = 'https://dev-api-lx.projotech.com/';
const clientUrl = 'https://dev-lx.projotech.com';

export const environment = {
  production: true,
  defaultTheme : 'theme5',
  application: {
    clientUrl,
    name: 'Projotech',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: apiUrl,
    redirectUri: clientUrl,
    clientId: 'Projotech_App',
    responseType: 'code',
    scope: 'offline_access Projotech',
    requireHttps: true,
  },
  apis: {
    default: {
      url: apiUrl,
      rootNamespace: 'LX.Projotech',
    },
  },
  firebaseConfig : {
    apiKey: "AIzaSyASEtCroXtLVWW-KHbZ3QRr5wNCDWeFoGc",
    authDomain: "lx-dev-f5570.firebaseapp.com",
    projectId: "lx-dev-f5570",
    storageBucket: "lx-dev-f5570.appspot.com",
    messagingSenderId: "236514460941",
    appId: "1:236514460941:web:b7f8ee52d8cae676161c44",
    vapidKey : 'BEP_pCO2dNxq0aUbOWDKJpA5Xv5h3RCJhrBb6bVBxPnTv6zOsJyXbLzfKZOMW5hHBNNarXZr8xEV_bkfefgyYmU'
  }
} as ExtendedEnvironment;
