import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { UserNotificationsDto, UserRegistrationTokenSetDto } from '../common-contracts/notifications/models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  apiName = 'Default';
  

  getNotifications = (input: PagedResultRequestDto, cancellationToken?: any, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UserNotificationsDto>({
      method: 'GET',
      url: '/api/app/notification/notifications',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  setAllNotificationsAsSeen = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/app/notification/set-all-notifications-as-seen',
    },
    { apiName: this.apiName,...config });
  

  setNotificationAsSeen = (notificationId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'GET',
      url: `/api/app/notification/set-notification-as-seen/${notificationId}`,
    },
    { apiName: this.apiName,...config });
  

  setRegistrationToken = (input: UserRegistrationTokenSetDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/notification/set-registration-token',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
