import type { ClassProjectMissingDepartment, MasterProjectToAssignDto, ProjectEffortDistributionResponse } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AssignProjectCheckDto, AssignProjectDto } from '../common-contracts/projects/models';

@Injectable({
  providedIn: 'root',
})
export class ProjectAssignService {
  apiName = 'Default';
  

  assignProject = (input: AssignProjectDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/project-assign/assign-project',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  calculateEffortDistribution = (input: AssignProjectDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectEffortDistributionResponse>({
      method: 'POST',
      url: '/api/app/project-assign/calculate-effort-distribution',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  getMasterProject = (masterProjectId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MasterProjectToAssignDto>({
      method: 'GET',
      url: `/api/app/project-assign/master-project/${masterProjectId}`,
    },
    { apiName: this.apiName,...config });
  

  getTeachersAutoCheck = (input: AssignProjectCheckDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ClassProjectMissingDepartment[]>({
      method: 'POST',
      url: '/api/app/project-assign/get-teachers-auto-check',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
