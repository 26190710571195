import { ProjectStageStatus } from '@proxy/projects';
import { CustomProjectTaskDto } from '../models/project/custom-project-task-dto';

class UtilityTools {

  isOverFlow(element: HTMLDivElement, className: string) {
    let isOverFlow = element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth;
    if (isOverFlow) {
      let div = document.getElementsByClassName(className);
      let element = div[0];
      element.classList.add('no-space-around');
    } else {
      element.classList.remove('no-space-around');
    }
    return true;
  }

  get_url_extension(url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  clearStorage() {
    localStorage.setItem('taskId', '');
  }

  // Begin Utility Methods
  public getExactedUrl = (action: string): string => {
    if (action.includes('#')) {
      let split = action.split('#');
      action = split[1];
      return action;
    }
    return action;
  };

  isFloat(value) {
    return typeof value === 'number' &&
      !Number.isNaN(value) &&
      !Number.isInteger(value);
  }

  public getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }

  float(number: number, parsing = 2) {
    return this.isFloat(number) ? parseFloat(this.getFlooredFixed(number, parsing)) : number;
  }

  public filterByStudentId(list: CustomProjectTaskDto[], studentId: any) {
    if (!studentId) return list;
    let filtered: CustomProjectTaskDto[] = [];
    list.forEach(a => a.assignees.forEach(assignee => {
      if (assignee.id === studentId)
        filtered.push(a);
    }));

    return filtered;
  }

  stageStatus(status: ProjectStageStatus) {
    switch (status) {
      case ProjectStageStatus.InProgress:
        return '#1dd78f';
      case ProjectStageStatus.DoneOnTime:
        return '#0283ff';
      case ProjectStageStatus.DoneWithDelay:
        return '#F442CE';
      case ProjectStageStatus.Overdue:
        return '#e81041';
      default:
        break;
    }
  }
}

export default new UtilityTools();
